
.pt_storefront {
    a.chevron-button-link {
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
        position: relative;
        background: none;
        border: none;
        color: #fff;
        width: auto;
        height: 64px;
        padding: 0;
        margin-top: 4px;
        font: normal normal bold 14px/64px $f-neue;
        letter-spacing: 0.5px;
        text-decoration: none;
        text-transform: uppercase;
        overflow: visible;
        &::after {
            content: " ";
            background: url("../images/button-arrow-white.svg") no-repeat 6px 14px;
            width: 26px;
            height: 39px;
            transition: all 0.4s ease;
            transform-style: preserve-3d;
            filter: invert(34%) sepia(11%) saturate(350%) hue-rotate(42deg) brightness(93%) contrast(89%);
            position: relative;
            top: 15px;
            right: 1px;
            display: inline-block;
        }
    }

    .hero {
        position: relative;
        display: flex;
        @include bp(max-width, $bp-custom-large) {
            flex-direction: column;
        }
        .hero-left-col {
            flex: 1 0 25%;
            background-color: #f7f7f7;
            padding: 170px 35px 0 41px;
            @include bp(max-width, $bp-custom-large) {
                order: 2;
                padding: 32px 11.2%;
            }
            h2 {
                font: normal normal 500 12px $f-neue;
                line-height: normal;
                letter-spacing: 4px;
                text-align: left;
                color: $black-two;
            }
            h3 {
                font: normal normal normal 22px/1.36 $f-primary;
                letter-spacing: 1px;
                color: $black-two;
            }
            p {
                font: normal normal normal 14px/1.43 $f-neue;
                letter-spacing: normal;
            }
        }
        .hero-right-col {
            flex: 1 0 75%;
            img {
                width: 100%;
                vertical-align: top;
            }
            @include bp(max-width, $bp-custom-large) {
                order: 1;
            }
        }
        .hero-image {
            height: 93vh;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-attachment: scroll;
            background-size: cover;
            position: relative;
            max-height: 355px;
            &::before {
                @extend %image-hero-top-gradient;
            }

            @include bp(min-width, $bp-large) {
                max-height: none;
                &.desktop::after {
                    content: "";
                    display: block;
                    position: absolute;
                    height: 280px;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    opacity: 0.69;
                    background-image: linear-gradient(0deg, $black-two 1%, $black-two 36%, rgba(36, 36, 36, 0) 100%);
                }
            }
        }
        a {
            text-decoration: none;
        }
        .content {
            color: $brand-primary-dark;
            text-align: center;
            margin-top: 16px;
            h1,
            h2,
            h3,
            h4,
            h5 {
                color: inherit;
            }
            button {
                margin-top: 1px;
                @include bp(max-width, $bp-large) {
                    color: $brand-primary-dark !important;
                }
            }
            h1 {
                margin-top: 4px;
            }
            h3 {
                margin-top: 2px;
            }

            @include bp(min-width, $bp-large) {
                margin: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                color: $white;
                right: 0;
                z-index: 1;
                padding-bottom: 48px;
                h3 {
                    margin-top: 0;
                }
                button {
                    margin-top: 17px;
                }
                h1 {
                    margin-top: 17px;
                }
            }
            @include bp(max-width, $bp-large) {
                padding: 16px;
            }
        }
    }

    .hp-comfort-club-banner {
        display: block;
        width: 100%;
        height: 62px;
        background-image: linear-gradient(to left, #384449, #29334f 47%, #253b43);

        @include bp(max-width, $bp-large) {
            height: 86px;
        }

        .menu-slot {
            width: 100%;
            display: block;

            @include bp(max-width, $bp-large) {
                padding-top: 0;
            }

            .hp-cc-club-banner {
                display: flex;
                justify-content: center;

                @include bp(max-width, $bp-large) {
                    display: block;
                    text-align: center;
                }

                h4 {
                    font: normal normal normal 24px/64px $f-primary;
                    letter-spacing: 1.64px;
                    color: #fff;

                    @include bp(max-width, $bp-large) {
                        display: block;
                        font-size: 18px;
                        text-align: center;
                        line-height: 18px;
                        min-width: 100%;
                        padding-top: 23px;
                    }
                }

                p {
                    font: normal normal normal 16px/64px $f-primary;
                    letter-spacing: 1.5px;
                    color: #fff;
                    margin: 0 20px 0 7px;

                    @include bp(max-width, $bp-large) {
                        max-width: 50%;
                        font-size: 18px;
                        line-height: 18px;
                        display: inline-block;
                        margin-right: 26px;
                    }

                    b {
                        font-size: 24px;

                        @include bp(max-width, $bp-large) {
                            max-width: 50%;
                            font-size: 18px;
                            line-height: 18px;
                        }
                    }
                }

                a.chevron-button-link {
                    margin-top: 2px;

                    @include bp(max-width, $bp-large) {
                        height: auto;
                        line-height: 18px;
                        margin-left: -15px;
                        margin-right: 0;

                        &::after {
                            top: 15px;
                            right: 0;
                        }
                    }
                }
            }
        }
    }

    .hp-shop-by-cat {
        .hp-shop-by-cat-wrapper,
        .hp-shop-by-cat-wrapper,
        .hp-shop-by-cat-row,
        .hp-shop-by-cat-col,
        .hp-shop-by-cat-tile {
            display: flex;
        }
        .hp-shop-by-cat-wrapper {
            flex-direction: column;
            flex: 1;
            .hp-shop-by-cat-col {
                flex-direction: column;
                @include bp(max-width, $bp-large) {
                    width: 100%;
                }
            }
            .hp-shop-by-cat-row {
                flex-direction: row;
                width: 100%;
                display: flex;
                -ms-flex-pack: center;
                justify-content: center;
                align-content: flex-end;
                padding-bottom: 30px;
                &.flex-bottom {
                    align-items: flex-end;
                }
                &.flex-top {
                    align-items: flex-start;
                }
                &.flex-center {
                    align-items: center;
                }
                @include bp(max-width, $bp-large) {
                    flex-direction: column;
                }
                @include bp(min-width, $bp-large) {
                    padding-left: 20px;
                }
                .hp-shop-by-cat-col {
                    padding-bottom: 48px;
                    @include bp(max-width, $bp-large) {
                        padding-bottom: 0;
                    }
                    h5 {
                        font-size: 28px;
                        font-weight: normal;
                        line-height: 1.29;
                        letter-spacing: 1.3px;
                        color: $black-two;
                        border-bottom: 0;
                        padding-bottom: 12px;
                        margin-bottom: 32px;
                        @include bp(max-width, $bp-large) {
                            padding-right: 20px;
                            padding-left: 20px;
                        }
                        a {
                            padding-bottom: 12px;
                            margin-bottom: 32px;
                            display: inline;
                            text-decoration: none;
                            text-transform: uppercase;
                            border-bottom: 1px solid $black-two;
                        }
                    }
                }
                .hp-shop-by-cat-tile {
                    flex-direction: column;
                    margin-right: 3.9%;
                    &:nth-last-child {
                        margin-right: 0;
                    }
                    @include bp(max-width, $bp-large) {
                        padding: 0 20px;
                        margin-right: 0;
                        min-width: 100%;
                        padding-bottom: 42px;
                    }
                    img {
                        display: block;
                        width: 100%;
                        @include bp(max-width, $bp-large) {
                            width: 100%;
                            min-width: 100%;
                        }
                    }
                    a.chevron-button-link {
                        color: $dark-sage;
                        height: auto;
                        line-height: 1.29;
                        margin-top: -13px;
                        &::after {
                            filter: $filter-black-to-dark-sage;
                            position: relative;
                            top: 15px;
                            right: 1px;
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }
    .hp-collection {
        padding-top: 54px;
        .menu-slot {
            width: 100%;
            display: block;
            img {
                vertical-align: top;
            }
            .collection-wrapper {
                display: flex;
                flex-direction: row;
                @include bp(max-width, $bp-custom-large) {
                    flex-direction: column;
                }
                .collection-left-col {
                    flex-basis: 59.722%;
                    @include bp(max-width, $bp-custom-large) {
                        order: 1;
                        min-width: 100%;
                        img {
                            width: 100%;
                        }
                    }
                }
                .collection-right-col {
                    flex-basis: 40.278%;
                    background-color: #f7f7f7;
                    @include bp(max-width, $bp-custom-large) {
                        order: 2;
                        min-width: 100%;
                    }
                    max-width: 40.278%;
                    h5,
                    p {
                        padding-left: 21.03%;
                        padding-right: 16.03%;
                        @include bp(max-width, $bp-custom-large) {
                            padding-left: 14.4%;
                            padding-right: 8%;
                        }
                    }
                    h5 {
                        padding-top: 205px;
                        font: normal normal normal 28px/1.29 $f-primary;
                        letter-spacing: 1.3px;
                        color: $black-two;
                        text-transform: uppercase;
                        @include bp(max-width, $bp-custom-large) {
                            font-size: 22px;
                            padding-top: 36px;
                        }
                    }
                    p {
                        font: normal normal normal 14px/1.43 $f-neue;
                        letter-spacing: normal;
                        color: $dark-sage;
                        position: relative;
                        &::before {
                            content: "\201C";
                            font: normal normal 300 80px/1 $f-neue;
                            letter-spacing: 2.86px;
                            color: $black-two;
                            position: absolute;
                            left: 14%;
                            top: -16px;
                        }
                    }
                    img {
                        width: 51.724%;
                        margin-left: 21.03%;
                        @include bp(max-width, $bp-custom-large) {
                            width: 61.33333%;
                            margin-left: 14.4%;
                        }
                    }
                    button {
                        margin-left: 21.03%;
                        @include bp(max-width, $bp-custom-large) {
                            margin-left: 14.4%;
                            width: 200px;
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }
    .hp-utilities {
        display: flex;
        justify-content: center;
        padding: 90px 3% 0;
        @include bp(max-width, $bp-large) {
            flex-flow: row wrap;
        }
        .hp-utli-tile {
            flex-direction: column;
            margin-right: 3.125%;
            @include bp(max-width, $bp-large) {
                margin-right: 0;
                width: 50%;
                margin-bottom: 32px;
                &:nth-child(odd) {
                    margin-right: 15px;
                    width: calc(50% - 15px);
                }
            }
            img {
                display: block;
                max-width: 255px;
                width: 100%;
                @include bp(max-width, $bp-large) {
                    max-width: calc(44vw);
                }
            }

            a.chevron-button-link {
                color: $dark-sage;
                height: auto;
                line-height: 1.29;
                margin-top: -13px;
                span {

                    @include bp(max-width, $bp-large) {
                        display: none;
                    }
                }
            }
        }
    }
}

.pt_storefront #main .store-locator-login {
    background: #f1f1f1;

    @include bp(min-width, $bp-medium) {
        display: none;
    }

    &::before {
        border-right: 1px solid $brand-grey;
        position: relative;
        content: "";
        display: inline-block;
        width: 1px;
        height: 40px;
        color: blue;
        left: 50%;
    }

    span.mobile {
        display: inline-block;
        text-align: center;
        width: 48%;
        padding: 12px 0 15px;

        a {
            font: normal normal 14px/19px $f-neue-bold;
            color: $brand-primary-dark !important; // use this for turning off active states
            letter-spacing: 1px;
            text-transform: uppercase;
            position: relative;
            display: block;
            @extend %anchor-no-decoration;
        }

        &.store-locator {
            &::before {
                @extend %icon-font;
                vertical-align: middle;
                margin-top: -4px;
                font-size: 21px;
                content: $icon-store-locator-marker;
                color: $brand-primary-dark;
                display: inline-block;
            }
        }

        &.login-link {
            &::before {
                @extend %icon-font;
                vertical-align: middle;
                margin-top: -4px;
                font-size: 21px;
                content: $icon-login;
                color: $brand-primary-dark;
                display: inline-block;
            }
        }
    }


}

.pt_storefront #main .our-ethos-block {
    background: $dark-sage;
    text-align: center;
    margin-top: 8px;
    padding: 19.3px 0 16.3px;

    a {
        font: normal normal 14px/19px $f-neue-bold;
        color: $white !important; // use this for turning off active states
        letter-spacing: 1px;
        text-transform: uppercase;
        position: relative;
        @extend %anchor-no-decoration;

        &::before {
            @extend %icon-font;
            margin-right: 12px;
            vertical-align: middle;
            margin-top: -4px;
            font-size: 21px;
            content: $icon-ethos;
            color: $white;
            display: inline-block;
        }
    }
}

#wrapper.pt_storefront .featured-content {

    h3 {
        margin: 63px 0 17px;
    }

    h2 {
        margin-bottom: 13px;
    }

    p {
        margin-bottom: 17px;
    }

    .grid-container .bump-up {
        margin-top: -93px;

        @include bp(max-width, $bp-large) {
            margin-top: 0;
            padding-right: 40px;
        }
    }

    .grid-container .has-caption {
        position: relative;

        .caption {
            font-size: 12px;
            line-height: 18px;
            position: absolute;
            bottom: 12px;
            display: inline-block;
            max-width: 121px;
            text-align: right;

            &.caption-left {
                left: -66px;
                text-align: right;
            }

            &.caption-right {
                right: -66px;
                text-align: left;
            }

            @include bp(max-width, $bp-large) {
                display: none;
            }
        }
    }

    .quote {
        padding-top: 27px;
        padding-right: 61px;

        .signatures,
        .button {
            margin-left: 66px;
        }

        .signatures {
            margin-top: 27px;
        }

        .button {
            margin-top: 19px;
        }
    }

    @include bp(max-width, $bp-large) {
        .grid-container div[class^="grid-span-"] {
            img {
                max-width: 100%;
                width: 100%;
                height: auto;
            }

            p {
                margin: 18px 0 12px;
            }
        }

        .quote {
            padding-right: 0;
            padding-top: 6px;

            blockquote {
                margin-top: 60px;
                padding-right: 16px;
            }

            .signatures {
                margin: 17px 0 0;
                padding-right: 16px;
                max-width: 220px;
            }

            .button {
                margin: 14px 0 0;
            }
        }

        h3 {
            margin: 0 0 8px;
        }

        h2 {
            margin-bottom: 20px;
        }
    }

    @include bp(min-width, $bp-large) {
        p {
            font-size: 16px;
            line-height: 26px;
        }
    }
}

.pt_storefront .subfooter.old {
    margin-top: 26px;
    @extend %constrain-wrapper;
    max-width: 1134px;
    box-sizing: border-box;
    @include clearfix;

    @include bp(min-width, $bp-medium) {
        margin-top: 57px;
        padding: 0 39px;
    }

    @include bp(min-width, $bp-large) {
        padding: 0 51px;
    }

    .footer-item {
        float: left;
        width: 29.6%;
        padding-top: 16px;
        p {
            margin-top: 20px;
        }
        &.second {
            padding-top: 0;
            width: 40.8%;
        }
        &.first p {
            margin-bottom: 5px;
        }
        .sharing {
            margin-top: 16px;
            a {
                margin-left: 13px;
                &:first-child {
                    margin-left: 0;
                }
                &.pinterest {
                    margin-left: 11px;
                }
                &.twitter {
                    margin-left: 10px;
                }
            }
        }

        @include bp(max-width, $bp-medium) {
            width: 100%;
            padding-left: 16px;
            padding-right: 16px;
            &.second {
                width: 100%;
                margin-bottom: 18px;
            }
            &.last {
                padding-top: 53px;
            }
            h3 {
                font-size: 28px;
                line-height: 34px;
            }
        }
    }
}

.pt_storefront .footer-container {
    .subfooter {
        padding: 0;
        max-width: 1140px;
    }
}

.old #email-alert-signup {
    .field-wrapper,
    .form-row {
        float: left;
        width: 100%;
        margin-bottom: 0;
        text-align: left;

        input {
            height: 30px;
            padding: 0 12px;
            font-size: 13px;
        }

        input:focus {
            &::-moz-placeholder,
            &:-ms-input-placeholder,
            &::-webkit-input-placeholder {
                opacity: 0;
            }
        }

        label {
            top: 8px;

            .required-indicator {
                display: none;
            }
        }

        &.input-focused label {
            display: none;
        }
    }

    form {
        text-align: left;
    }

    .form-row {
        margin: 0;
    }

    .button {
        width: 100%;
        height: 30px;
        padding: 0;
        margin-top: 12px;
        font-size: 13px;
    }

    @include bp(min-width, $bp-large) {
        .form-row {
            max-width: 195px;
            width: 64%;
        }

        .button {
            float: right;
            width: 34%;
            margin-top: 0;
        }
    }
}

#wrapper.pt_storefront {
    #main {
        max-width: 1440px;
    }
}
.IE {
    .pt_storefront {
        .hp-cc-club-banner {
            a.chevron-button-link::after {
                background: url("../images/button-arrow-white.svg") no-repeat 6px 14px;
            }
        }
        .hp-utilities .hp-utli-tile {
            a.chevron-button-link::after {
                background: url("../images/button-arrow-54584e.svg") no-repeat 6px 14px;
            }
        }
        .hp-shop-by-cat .hp-shop-by-cat-wrapper .hp-shop-by-cat-row .hp-shop-by-cat-tile {
            a.chevron-button-link::after {
                background: url("../images/button-arrow-54584e.svg") no-repeat 6px 14px;
            }
        }
    }
}
