.product-collection,
.zoomImageCarousel {
    display: none;
    z-index: 10;

    &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: 2px;
        height: 96.2vh;
        background: $brand-primary-dark;

    }

    &.open {
        display: block;
        position: absolute;
        top: 2%;
        right: 1.4%;
        left: 1.5%;
        height: 96.2vh;
        max-width: 100%;
        z-index: 10;
        margin: 0 auto;
        opacity: 0;
        transition: opacity $navigation-transition-duration $navigation-transition-function;
    }

    &.show {
        opacity: 1;
    }

    .item {
        height: 96.2vh;
        width: 97.5%;
        margin: 0 auto;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: scroll;
        background-size: cover;
        position: relative;

        .content {
            position: absolute;
            color: $white;
            top: 0;
            right: 0;
            max-width: 470px;
            width: 100%;
            text-align: center;
            padding: 35px 25px 21px;

            h2.bottom-border::after,
            h3.bottom-border::after {
                border-color: $white;
            }

            p {
                font-size: 16px;
                margin: 29px 0 17px;
                line-height: 26px;
            }

            h2,
            h3,
            button,
            .button {
                color: inherit;
            }

            .close {
                display: block;
                position: absolute;
                top: 0;
                right: 0;

                &::before {
                    @extend %icon-font;
                    font-size: 14px;
                    content: $icon-close-x;
                    color: $white;
                    background: $brand-primary-dark;
                    padding: 11px;
                    line-height: 36px;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }



    .flex-control-nav {
        bottom: 20px;
    }
}

.zoomImageCarousel .item {
    background-color: $white;
    background-size: contain;

    .content {
        padding: 36px 25px 40px;
    }
}

.zoomImageCarousel .flex-direction-nav a.flex-next::before,
.zoomImageCarousel .flex-direction-nav a.flex-prev::before {
    color: $brand-primary-light;
}
